<template>
    <v-row class="ma-0">
        <v-col cols="6">
            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Expiry Month *</v-subheader>
            <v-autocomplete ref="month" v-model="model.month"
                :error-messages="$helpers.errorMsg('name', $v.model.month, 'Expiry Month')"
                hide-details="auto" placeholder="Expiry Month" required
                :items="monthLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                outlined
            >
            </v-autocomplete>
        </v-col>
        <v-col cols="6">
            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Expiry Year *</v-subheader>
            <v-autocomplete ref="year"  v-model="model.year"               
                :error-messages="$helpers.errorMsg('name', $v.model.year, 'Expiry Year')"
                hide-details="auto" placeholder="Expiry Year" required
                :items="yearLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                outlined
            >
            </v-autocomplete>
        </v-col>
        <v-col cols="12">
            <v-card-actions class="pa-0">
                <v-row class="ma-0 pa-3" align="center" justify="end">
                    <v-btn :disabled="loading" color="primary" class="text-capitalize mr-3" @click="openNotify()">Save</v-btn>
                    <v-btn :disabled="loading" color="secondary" class="text-capitalize" @click="$emit('enableAct', false)">Cancel</v-btn>
                </v-row>
            </v-card-actions>
        </v-col>
        <notify-modal :loading="loading" :modalData="modalData" :open="openModal" @save="saveNotify" @close="openModal = false"/>
    </v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';

export default {
    props: ['modelData'],
    emits: ['enableAct'],
    components: {
        NotifyModal: () => import("@/pages/admin/sf-opportunity/components/NotifyModal"),
    },
    data: (vm) => ({
        model: { month: '', year: ''},
        monthLists: vm.$helpers.getMomentMMYYLists(),
        yearLists: vm.$helpers.getMomentMMYYLists(21, 'YYYY'),
        modalData: {
            name: 'save',
            items: [],
            tableNotFound: 'No Data Found!.',
        },
        openModal: false,
        loading: false,
        defaultModel: {},
    }),
    validations: {
        model: {
            month: { required },
            year: { required },
        }
    },
    watch: {
        modelData(newVal) {
            this.defaultModel = newVal;
        }
    },
    created() { this.defaultModel = this.modelData; }, 
    methods: {
        ...mapActions(['donateUpdateExp']),

        openNotify() {
            this.$v.$touch();
            if (this.$v && this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            this.openModal = true;
            this.$emit('enableAct', true);
        },
        saveNotify() {
            this.loading = true;
            const reqData = {
                card: {
                    exp_month: this.$helpers.getMomentMonYearFormat(this.model.month, 'M'),
                    exp_year: this.model.year
                }
            }
            this.donateUpdateExp({id: this.defaultModel.id, reqData: reqData}).then(resp => {
                this.openModal = false;
                this.loading = false;
                this.$emit('enableAct', false);
            }).catch(err =>  this.loading = false)
        }
    }
}
</script>
